import {
  CartIcon,
  CarFrontIcon,
  SearchIcon,
  TagIcon,
} from "@common/components/icons";
import React, { ReactNode } from "react";
import { TranslationKeys } from "src/@types/i18next";

export enum VehicleOwnershipStatusId {
  RecentlyBoughtVehicle = 1,
  HaveAnExistingVehicle = 2,
  JustStartedShopping = 3,
  WillBuyAVehicleSoon = 4,
}

export type VehicleOwnershipStatus = {
  id: number;
  name: TranslationKeys["purchase"];
  icon?: ReactNode;
};

export const vehicleOwnershipStatuses: VehicleOwnershipStatus[] = [
  {
    id: 1,
    name: "purchase:recently_bought_vehicle",
    icon: React.createElement(TagIcon, {
      className: "block text-primary-deep m-auto w-6 h-6",
    }),
  },
  {
    id: 2,
    name: "purchase:have_an_existing_vehicle",
    icon: React.createElement(CarFrontIcon, {
      className: "block text-primary-deep m-auto w-6 h-6",
    }),
  },
  {
    id: 3,
    name: "purchase:just_started_shopping_for_vehicles",
    icon: React.createElement(SearchIcon, {
      className: "block text-primary-deep m-auto w-6 h-6",
    }),
  },
  {
    id: 4,
    name: "purchase:will_buy_a_vehicle_soon",
    icon: React.createElement(CartIcon, {
      className: "block text-primary-deep m-auto w-6 h-6",
    }),
  },
];

export function getVehicleOwnershipStatusById(
  id: number
): VehicleOwnershipStatus | undefined {
  return vehicleOwnershipStatuses.find((s) => s.id === id);
}
